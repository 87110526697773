// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aktuelles-jsx": () => import("./../../../src/pages/aktuelles.jsx" /* webpackChunkName: "component---src-pages-aktuelles-jsx" */),
  "component---src-pages-alle-termine-jsx": () => import("./../../../src/pages/alle-termine.jsx" /* webpackChunkName: "component---src-pages-alle-termine-jsx" */),
  "component---src-pages-archiv-jsx": () => import("./../../../src/pages/archiv.jsx" /* webpackChunkName: "component---src-pages-archiv-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-termine-jsx": () => import("./../../../src/pages/termine.jsx" /* webpackChunkName: "component---src-pages-termine-jsx" */),
  "component---src-templates-appointment-jsx": () => import("./../../../src/templates/appointment.jsx" /* webpackChunkName: "component---src-templates-appointment-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

