// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"

export const onServiceWorkerUpdateReady = () => {
  /* const answer = window.confirm(
    `Diese Seite wurde inzwischen aktualisiert. ` +
      `Soll diese Seite neu geladen werden, um die jüngste Version anzuzeigen?`
  )
  if (answer === true) {
          window.location.reload()
  } */
  window.location.reload()
}

/*
export const onRouteUpdate = location => {
  if (location.hash) {
    setTimeout(() => {
      document.querySelector(`${location.hash}`).scrollIntoView()
    }, 0)
  }
}
*/
